import {React} from "react";
import Notes from "./Notes";
//rafc
const Home = (props) => {
 const {showAlert}=props;
  return (
    <div>
      <Notes showAlert={showAlert}/>
    </div>
  );
};

export default Home;
